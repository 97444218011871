import React from 'react';
import { Wheel } from 'react-custom-roulette';
import { useSpin } from '../context/SpinContext';
import { useFetchWheelData } from '../hooks/useFetchWheelData';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

type WheelComponentProps = {
    spinRef: string;
};

const WheelComponent: React.FC<WheelComponentProps> = ({ spinRef }) => {
    const { data, isLoading } = useFetchWheelData(spinRef);
    const { mustSpin, setMustSpin, setHasFinishedSpinning, hasStartedSpinning, hasFinishedSpinning, setHasStartedSpinning } = useSpin();

    const handleSpinClick = () => {
        setMustSpin(true);
        setHasStartedSpinning(true);
    };

    if (isLoading) return <div>Loading...</div>;

    // Ensure data format aligns with the new API structure
    const wheelData = data?.data?.wheel_items?.map((item: string, idx: number) => ({ id: idx, option: item })) || [];
    const title = data?.data?.wheel_title || 'Loading...';
    const outcome = data?.data?.outcome || 0;

    return (
        <div className="container my-5">
            <div className="card shadow-lg">
                <div className="card-header d-flex justify-content-center align-items-center">
                    <h4 className='m-0'>{title}</h4>
                </div>

                <div className="card-body text-center">
                    <div className="d-flex justify-content-center my-4">
                        <Wheel
                            mustStartSpinning={mustSpin}
                            prizeNumber={outcome}
                            data={wheelData}
                            outerBorderColor="#f2f2f2"
                            outerBorderWidth={10}
                            innerBorderColor="#f2f2f2"
                            radiusLineColor="#dedede"
                            radiusLineWidth={2}
                            textColors={["#ffffff"]}
                            fontSize={20}
                            perpendicularText={true}
                            backgroundColors={["#942f54", "#d35e5f", "#e2b58c"]}
                            onStopSpinning={() => {
                                setMustSpin(false);
                                setHasFinishedSpinning(true);
                                
                                fetch(`https://seahorse-app-46qz2.ondigitalocean.app/viewed/${spinRef}`, {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json, text/plain, */*',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({ a: 7, str: 'Some string: &=&' })
                                })
                                .then(res => res.json())
                                .then(res => console.log(res));
                            }}
                        />
                    </div>

                    <div className="row m-3 text-center">
                        {!hasStartedSpinning && !hasFinishedSpinning && (
                            <Button size="lg" onClick={handleSpinClick}>Spin</Button>
                        )}
                        {hasStartedSpinning && !hasFinishedSpinning && (
                            <h4 className="px-0">Result: ?</h4>
                        )}
                        {hasStartedSpinning && hasFinishedSpinning && (
                            <h4 className="px-0">
                                <strong>Result:</strong> <span className="text-primary">{wheelData[outcome]?.option || '?'}</span>
                            </h4>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WheelComponent;
